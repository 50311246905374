
.footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	background-color: #25408f;
	
	div.container {
		text-align: center;
		p {
			color: #fff;
			a {
				color: gold;
			}
		}
	}
}
