
.ced_header {
  clear: both;
  margin-top: 80px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-size: 26px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
	border-bottom: 5px solid #0C9ED9;
}
