.cedLogo {
	background: none !important;
	
	&:hover {
		background: rgba(255, 255, 255, .08) !important;
	}
	
	img {
		height: 100% !important;
		width: 100% !important;
	}
}

.navBar {
	max-height: 99px;
}

.lineLogo {
	display: none;
	align-items: center;
	margin-bottom: 30px;
}

.lineLogo::before,
.lineLogo::after {
	content: '';
	border: 1px solid gray;
	flex: 1;
}

.lineLogo img {
	margin: 0 1em;
}