
.filedropzone {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	min-height: 6em;
	border: 1px dashed black;
	background-color: #eee;
	padding: 16px 0px;
	
	* {
		margin: 0px;
		list-style-type: none;
	}
	
	button {
		padding: .2em;
		margin-right: .5em;
	}
}
