#notificationDetails {
    z-index: 101;
    position: absolute;
    min-width: 20em;
    transform: translateX(-90%);
    top: 50%;
}

.bellWrapper:hover {
  background: rgba(255,255,255,.08);
  color: #fff;
}

.bellWrapper {
  padding: 13px 17px;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.bellRedDot {
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 50%;
  position: absolute;
  transform: translate(20%, -50%);
}

.menuRedDot {
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
  position: absolute;
  transform: translate(-110%, -115%);
}

.notifCount {
  font-size: 16px;
  text-align: center;
}